<template>
    <div class="w-100">
        <div class="box mt-3">
            <!-- <div v-if="nbDevice.ios" class="row">
                <div class="col-6">
                    Nombre total d'utilisateur sur IOS
                </div>
                <div class="col-6">
                    {{nbDevice.ios}}
                </div>
            </div> -->
            <div v-if="nbDevice.ios">
                Nombre total d'utilisateurs sur IOS : {{nbDevice.ios}}
            </div>
        </div>
        <div class="box mt-3">
            <div v-if="nbDevice.android">
                Nombre total d'utilisateurs sur Android : {{nbDevice.android}}
            </div>
        </div>
        <div class="box mt-3">
            <div v-if="nbUser">
                Nombre total d'utilisateurs : {{nbUser}}
            </div>
        </div>
        <div class="box mt-3">
            <div v-if="nbLicence">
                Nombre total de licences : {{nbLicence}}
                <div
                    v-for="gamme in nbLicenceGamme"
                    :key="gamme.gamme_id"
                >
                    - {{gamme.gamme_label}} : {{gamme.nb}}<br>
                </div>
            </div>
        </div>
        <div class="box mt-3">
            <div v-if="nbHorse">
                Nombre total de chevaux : {{nbHorse}}
            </div>
        </div>
    </div>
</template>


<script type="text/javascript">
import StatistiquesMixin from "@/mixins/Statistiques.js";

export default {
    name: "ChiffreApp",
    mixins: [StatistiquesMixin],
    data () {
        return {
            nbDevice: '',
            nbUser : '',
            nbLicence : '',
            nbLicenceGamme : '',
            nbHorse : ''
        }
    },
    mounted:function(){
        this.getData()
    },
    methods: {
        async getData() {

            const getDevices = await this.getDevices()
            .catch(e => {
                console.log("Erreur sur getDevices", e)
            })
            this.nbDevice = getDevices

            const getNbUser = await this.getNbUser()
            .catch(e => {
                console.log("Erreur sur getNbUser", e)
            })
            this.nbUser = getNbUser

            const getNbLicence = await this.getNbLicence()
            .catch(e => {
                console.log("Erreur sur getNbLicence", e)
            })
            this.nbLicence = getNbLicence.nbLicence
            this.nbLicenceGamme = getNbLicence.nbLicenceGamme

            const getNbHorse = await this.getNbHorse()
            .catch(e => {
                console.log("Erreur sur getNbHorse", e)
            })
            this.nbHorse = getNbHorse

        },
    },
}
</script>
